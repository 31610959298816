import ListComponent from "../list component/ListComponent";
import "../list/List.css";

function List({
  myExerciseList,
  setMyExerciseList,
  selectedMuscle,
  setSelectedMuscle,
}) {
  return (
    <div id="list">
      {myExerciseList.reverse().map((exercise, index) => (
        <ListComponent
          key={index}
          exerciseId={exercise.id}
          exerciseName={exercise.exercise_name}
          exerciseSteps={exercise.steps}
          exerciseVideoURL={exercise.videoURL}
          exerciseCategory={exercise.Category}
          exerciseMuscle={exercise.Primary}
          myExerciseList={myExerciseList}
          setMyExerciseList={setMyExerciseList}
          selectedMuscle={selectedMuscle}
          setSelectedMuscle={setSelectedMuscle}
        />
      ))}
    </div>
  );
}

export default List;
