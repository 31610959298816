import React from "react";
import { useState, useEffect } from "react";
import data from "../../data/data.json";
import "../search result/SingleSearchResult.css";

function SingleSearchResult({
  id,
  selectedMuscle,
  setSelectedMuscle,
  myExerciseList,
  setMyExerciseList,
}) {
  const exerciseInfo = data.find((exerciseObject) => exerciseObject.id === id);
  const [videoError, setVideoError] = useState(false);
  let modifiedExerciseVideoURL = exerciseInfo.videoURL.map((video, index) => {
    return video.replace("male-", `male-${exerciseInfo.Category}-`);
  });

  const {
    id: exerciseId,
    exercise_name: exerciseName,
    videoURL: exerciseVideoURL,
    steps: exerciseSteps,
    Category: exerciseCategory,
    Primary: exerciseMuscle,
  } = exerciseInfo;

  const addExercise = () => {
    const newExercise = {
      id: exerciseId,
      exercise_name: exerciseName,
      steps: exerciseSteps,
      videoURL: exerciseVideoURL,
      Category: exerciseCategory,
      Primary: exerciseMuscle,
    };
    if (
      !myExerciseList.some(
        (exercise) => exercise.exercise_name === exerciseName
      )
    ) {
      const newSelectedMuscle = [...selectedMuscle, ...exerciseMuscle];
      setSelectedMuscle(newSelectedMuscle);
      setMyExerciseList((myExerciseList) => [...myExerciseList, newExercise]);
    }
  };
  useEffect(() => {}, [setSelectedMuscle]);

  const handleVideoError = () => {
    setVideoError(true);
  };

  return (
    <div className="exercise-search-results-single">
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target={"#" + exerciseId}
      >
        <div className="exercise-info">
          <div className="exercise-info-info">
            <h5 className="exercise-info-name">{exerciseName}</h5>
            <p className="exercise-info-muscle">
              Primary Muscle(s): {exerciseMuscle.join(", ")}
            </p>
          </div>

          {myExerciseList.some(
            (exercise) => exercise.exercise_name === exerciseName
          ) && (
            <img
              className="excerise-info-muscle"
              width="16%"
              src="icons/added.svg"
              alt="logo"
            />
          )}
        </div>
      </button>

      <div
        className="modal fade"
        id={exerciseId}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {exerciseName}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="videos">
                {!videoError ? (
                  <video
                    src={exerciseVideoURL[0]}
                    width="49%"
                    controls
                    muted
                    onError={handleVideoError}
                  ></video>
                ) : (
                  <video
                    src={modifiedExerciseVideoURL[0]}
                    width="49%"
                    controls
                    muted
                    onError={handleVideoError}
                  ></video>
                )}

                {!videoError ? (
                  <video
                    src={exerciseVideoURL[1]}
                    width="49%"
                    controls
                    muted
                    onError={handleVideoError}
                  ></video>
                ) : (
                  <video
                    src={modifiedExerciseVideoURL[1]}
                    width="49%"
                    controls
                    muted
                    onError={handleVideoError}
                  ></video>
                )}
              </div>
              <div className="muscle">
                <p id="muscle-text">
                  Primary Muscle(s): {exerciseMuscle.join(", ")}
                </p>
              </div>
              <div className="category">
                <p id="category-text">Category: {exerciseCategory}</p>
              </div>
              <div className="steps">
                {exerciseSteps.map((steps, index) => (
                  <div key={index} className="exercise-steps">
                    <i className={`bi bi-${index + 1}-circle-fill`} />
                    <p>{steps}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                data-bs-dismiss="modal"
                onClick={addExercise}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleSearchResult;
