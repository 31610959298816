import React from "react";
import "../nav/NavBar.css";

function NavBar() {
  return (
    <div id="header">
      <img id="header-logo" width="10%" src="icons/logo.svg" alt="logo" />
      <div id="header-disclaimer">
        <p id="header-disclaimer-message">
          <i className="bi bi-exclamation-circle-fill" />
          This website is developed by{" "}
          <a
            href="https://github.com/dyzhao1011"
            target="_blank"
            rel="noreferrer"
          >
            Michael
          </a>
          . The information and content shown on this website includes materials
          from{" "}
          <a href="https://musclewiki.com/" target="_blank" rel="noreferrer">
            MuscleWiki
          </a>{" "}
          for informational purposes. All credits are given to them.
        </p>
      </div>
    </div>
  );
}

export default NavBar;
