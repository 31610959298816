import React, { useEffect } from "react";
import "../search/SearchBar.css";
import { useState } from "react";
import data from "../../data/data.json";
import SingleSearchResult from "../search result/SingleSearchResult";

function SearchBar({
  selectedMuscle,
  setSelectedMuscle,
  myExerciseList,
  setMyExerciseList,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const onChange = (event) => {
    const userInput = event.target.value;
    setSearchQuery(userInput);
  };
  useEffect(() => {
    const suggestedExercises = data.filter((item) =>
      item.exercise_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchSuggestions(suggestedExercises.slice(0, 7));
  }, [searchQuery]);

  return (
    <div className="exercise">
      <label htmlFor="exercise-search" className="form-label">
        Enter an exercise
      </label>
      <input
        type="text"
        id="exercise-search"
        className="form-control"
        value={searchQuery}
        onChange={onChange}
      />

      <div id="exercise-search-results">
        {searchSuggestions.map((item, index) => (
          <SingleSearchResult
            key={index}
            id={item.id}
            selectedMuscle={selectedMuscle}
            setSelectedMuscle={setSelectedMuscle}
            myExerciseList={myExerciseList}
            setMyExerciseList={setMyExerciseList}
          />
        ))}
      </div>
    </div>
  );
}

export default SearchBar;
