import "../list component/ListComponent.css";
import { useState } from "react";

function ListComponent({
  exerciseId,
  exerciseName,
  exerciseSteps,
  exerciseVideoURL,
  exerciseCategory,
  exerciseMuscle,
  myExerciseList,
  setMyExerciseList,
  selectedMuscle,
  setSelectedMuscle,
}) {
  const [videoError, setVideoError] = useState(false);
  let modifiedExerciseVideoURL = exerciseVideoURL.map((video, index) => {
    return video.replace("male-", `male-${exerciseCategory}-`);
  });

  const handleVideoError = () => {
    setVideoError(true);
  };

  const deleteExercise = () => {
    const updatedExerciseList = myExerciseList.filter(
      (exercise) => exercise.exercise_name !== exerciseName
    );
    setMyExerciseList(updatedExerciseList);

    let updatedMuscles = selectedMuscle;
    for (let i = 0; i < exerciseMuscle.length; i++) {
      const indexOfMuscle = updatedMuscles.indexOf(exerciseMuscle[i]);
      const updatedMuscle = [...updatedMuscles];
      updatedMuscle.splice(indexOfMuscle, 1);
      updatedMuscles = updatedMuscle;
    }

    setSelectedMuscle(updatedMuscles);
  };
  return (
    <>
      <div
        className="card"
        data-bs-toggle="modal"
        data-bs-target={"#" + exerciseId + "2"}
      >
        <div className="card-body card-link btn btn-primary text-wrap">
          <h5 className="card-title">{exerciseName}</h5>
          <h6 className="card-subtitle mb-2 text-body-secondary">
            {exerciseMuscle.map((exercise, index) =>
              index !== exerciseMuscle.length - 1 ? (
                <span key={index}>{exercise}, </span>
              ) : (
                <span key={index}>{exercise} </span>
              )
            )}
          </h6>
        </div>
      </div>

      <div
        className="modal fade"
        id={exerciseId + "2"}
        tabIndex="-1"
        aria-labelledby="exerciseName"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exerciseName">
                {exerciseName}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-wrap">
              <div className="videos">
                {!videoError ? (
                  <video
                    src={exerciseVideoURL[0]}
                    width="49%"
                    controls
                    muted
                    onError={handleVideoError}
                  ></video>
                ) : (
                  <video
                    src={modifiedExerciseVideoURL[0]}
                    width="49%"
                    controls
                    muted
                    onError={handleVideoError}
                  ></video>
                )}

                {!videoError ? (
                  <video
                    src={exerciseVideoURL[1]}
                    width="49%"
                    controls
                    muted
                    onError={handleVideoError}
                  ></video>
                ) : (
                  <video
                    src={modifiedExerciseVideoURL[1]}
                    width="49%"
                    controls
                    muted
                    onError={handleVideoError}
                  ></video>
                )}
              </div>
              <div className="muscle">
                <p id="muscle-text">
                  Primary Muscle(s): {exerciseMuscle.join(", ")}
                </p>
              </div>
              <div className="category">
                <p id="category-text">Category: {exerciseCategory}</p>
              </div>
              <div className="steps">
                {exerciseSteps.map((steps, index) => (
                  <div key={index} className="exercise-steps">
                    <i className={`bi bi-${index + 1}-circle-fill`} />
                    <p>{steps}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={deleteExercise}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListComponent;
